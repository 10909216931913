<template>
  <div role="main" class="main">
    <header class="header">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-12">
            <nav class="navbar navbar-expand-md navbar-dark pl-lg-0">
              <a class="navbar-brand" href="javascript:void(0);">{{
                event ? event.decease_name : "MOURN WITH US"
              }}</a>
              <div class="navbar-list">
                <router-link
                  tag="a"
                  class="text-yellow hover-yellow mr-3 mr-md-5"
                  to="/speaker/profile"
                  >Profile</router-link
                >
              </div>
              <div class="action logout">
                <a
                  href="#"
                  class="text-yellow hover-yellow mr-3"
                  @click="logout"
                  ><i class="fas fa-sign-out-alt"></i>Logout</a
                >
                <button
                  type="button"
                  class="btn btn-danger"
                  @click="endEventLive"
                >
                  End Service
                </button>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </header>
    <section class="together_we_join minh-custom with-footer speaker-room-page">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-3 offset-md-1" v-if="current_user">
            <div class="border-l text-left">
              <p>{{ current_seq_name }}</p>
            </div>
            <div class="profile-pic">
              <img
                :src="current_user.profile_url"
                class="mr-2 mb-1 live_profile_img br-50"
              />
              {{ current_user.speaker_name }}
            </div>
            <h6 class="text-primary text-left mt-3">{{ current_user.bio }}</h6>
          </div>
          <div class="col-md-1"></div>
          <div class="col-md-4">
            <div
              class="mt-3 live-video"
              style="width: 100% !important; padding: 0"
            >
              <!-- show youtube live stream  -->
              <iframe
                style="
                  height: 332px;
                  width: 100%;
                  padding: 28px 30px;
                  z-index: 9;
                  position: relative;
                  left: 0px;
                "
                :src="youtube_url"
                allow="autoplay; encrypted-media"
                frameborder="0"
                allowfullscreen
                v-if="
                  current_user.content_type_id != content_type_id &&
                  videoflag == false
                "
              ></iframe>
              <!-- <iframe
                width="560"
                height="315"
                style="
                  z-index: 9;
                  position: absolute;
                  width: 89%;
                  height: 280px;
                  left: 27px;
                  top: 31px;
                "
                :src="video_source"
                allow="autoplay; encrypted-media"
                v-show="!runningVideo && runningSourceVideo"
              ></iframe> -->

              <!-- static video of speaker -->

              <div class="frame-wrapper c-frame-wrapper" style="width: 100%">
                <!-- show frame in youtube live stream if there is no static video-->

                <div
                  v-if="
                    !runningVideo && runningSourceVideo && videoflag == false
                  "
                  style="width: 100%"
                >
                  <img
                    :src="require('@/assets/img/frame.png')"
                    style="
                      position: absolute;
                      top: 0;
                      left: 0;
                      width: 100%;
                      height: 340px;
                      z-index: 1;
                    "
                  />
                </div>
                <!-- show frame in youtube live stream if there is static video-->
                <div
                  v-if="
                    !runningVideo && !runningSourceVideo && videoflag == false
                  "
                  style="width: 100%"
                >
                  <img
                    :src="require('@/assets/img/frame.png')"
                    style="
                      position: absolute;
                      top: 0;
                      left: 0;
                      width: 100%;
                      height: 340px;
                      z-index: 1;
                    "
                  />
                </div>
                <div
                  v-if="videoflag == true"
                  style="width: 100%; height: 340px; overflow: hidden"
                >
                  <img
                    :src="require('@/assets/img/frame.png')"
                    style="
                      position: absolute;
                      top: 0px;
                      left: 0px;
                      width: 100%;
                      height: 340px;
                      z-index: 1;
                    "
                  />
                  <div id="output"></div>
                  <video
                    :src="require('@/assets/video/sample-mp4-file.mp4')"
                    class="w-100"
                    style="position: relative; top: 0; height: 340px"
                    id="source_video"
                  ></video>
                </div>
                <!-- publisher of curent speaker -->
                <div
                  id="publisher"
                  class="img-fluid call"
                  v-if="
                    current_user.content_type_id == content_type_id &&
                    videoflag == false &&
                    !runningVideo &&
                    !runningSourceVideo
                  "
                ></div>
                <!-- publisher of static video speaker -->
                <div class="hide_publisher">
                  <div
                    id="publisher"
                    style="display: none"
                    v-if="videoflag == true"
                  ></div>
                </div>
              </div>

              <div class="text-center css-layout" v-if="show_css_layout">
                <div
                  class="d-flex align-items-center justify-content-center"
                  style="height: 100%"
                >
                  <h2 style="color: yellow">
                    STANDBY<br />FOR OUR<br />NEXT SPEAKER
                  </h2>
                </div>
              </div>
              <b-nav-item-dropdown id="dropdown-list" no-caret right>
                <template slot="button-content">
                  <i
                    class="fas fa-camera"
                    style="font-size: 36px; color: #fcd351"
                    @click="deviceList()"
                  ></i>
                </template>
                <b-dropdown-item
                  :id="'deviceid_' + device.deviceId"
                  v-for="device in devices"
                  :key="device.deviceId"
                  @click="switchCamera($event)"
                  >{{ device.label }}</b-dropdown-item
                >
                <b-dropdown-divider></b-dropdown-divider>
              </b-nav-item-dropdown>
            </div>
          </div>
          <div class="col-md-1"></div>
          <div class="col-md-2">
            <div class="participate participate-box" v-if="waiting">
              <div
                class="w-100 text-right"
                v-if="
                  current_user &&
                  current_user.content_type_id == content_type_id
                "
              >
                <button class="btn btn-blank" @click="endMyLive">END</button>
              </div>
              <ul class="list-unstyled mb-0" v-if="current_user">
                <li class="mr-0" style="margin-right: 10px">
                  <i>
                    <img :src="require('@/assets/img/i-icon.png')" />
                  </i>
                  <img
                    :src="current_user.profile_url"
                    class="img-fluid br-50"
                  />
                  <p class="text-custom mb-0" style="font-size: 1rem">
                    {{ current_user.speaker_name }}
                  </p>
                  <div
                    class="mt-2"
                    v-if="
                      current_user &&
                      current_user.content_type_id != content_type_id
                    "
                  >
                    <button
                      type="button"
                      class="btn btn-info btn-sm"
                      @click="passLive()"
                    >
                      {{ button_text }}
                    </button>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
    <footer class="container">
      <div class="row align-items-center">
        <div class="col-md-7">
          <div class="participate speaker_list">
            <ul class="list-unstyled mb-0">
              <li
                v-for="speaker in speakers"
                :key="speaker.content_type_id"
                style="margin-right: 10px"
              >
                <i>
                  <img :src="require('@/assets/img/i-icon.png')" />
                </i>
                <img :src="speaker.profile_url" class="img-fluid br-50" />

                <span
                  v-if="
                    event &&
                    speaker.content_type_id == current_user.content_type_id &&
                    speaker.speaker_type == 1
                  "
                >
                  Live
                </span>
                <span
                  v-if="
                    event &&
                    speaker.content_type_id == current_user.content_type_id &&
                    speaker.speaker_type == 0
                  "
                >
                  Live
                </span>
                <span
                  v-if="
                    event &&
                    speaker.content_type_id != current_user.content_type_id &&
                    speaker.speaker_type == 0
                  "
                >
                  VIDEO
                </span>
              </li>
            </ul>
          </div>
        </div>
        <div class="c-scroll" v-if="event">
                    <div  v-for="(input,k) in imagesArray" :key="k" class="mb-3">
                    <img
                      :src="input.program_url"
                      class="img-fluid pointer"
                      style="max-height: 110px"
                      @click="enlarge()"
                    />
                    </div>
                  </div>
        <!-- <div class="col-md-5 text-center text-lg-right" v-if="event">
          <img
            :src="event.program_url"
            class="img-fluid pointer"
            style="max-height: 110px"
            @click="enlarge()"
          />
        </div> -->
      </div>
    </footer>

    <!-- Eulogy Notes -->
    <transition name="pop" appear>
      <div
        class="custom-modal note-modal"
        v-if="current_user && current_user.content_type_id == content_type_id"
        id="EulogyNotes"
        tabindex="-1"
        role="dialog"
        aria-labelledby="Eulogy Notes"
        aria-hidden="true"
        v-draggable="draggableValue"
        style="z-index: 2"
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-body" style="background-color: black">
              <div class="row">
                <div class="col-lg-12">
                  <h5 class="text-custom text-left">
                    <img
                      :src="require('@/assets/img/move_icon.png')"
                      :ref="handleId"
                    />Notes
                  </h5>
                </div>
                <div
                  class="col-lg-12 text-custom mt-4 scroll-notes"
                  style="max-height: 200px"
                >
                  <p class="m-0">{{ notes }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>

    <!-- Countdown -->
    <transition name="pop" appear>
      <div
        class="modal fade show custom-modal"
        v-if="show_count_modal"
        id="Countdown"
        tabindex="-1"
        role="dialog"
        aria-labelledby="Countdown"
        aria-hidden="true"
        style="z-index: 9000"
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-body">
              <div class="row">
                <div class="col-lg-4 text-center">
                  <div class="participate">
                    <ul class="list-unstyled mb-0" v-if="current_user">
                      <li style="margin-right: 10px">
                        <i>
                          <img :src="require('@/assets/img/i-icon.png')" />
                        </i>
                        <img
                          :src="current_user.profile_url"
                          class="img-fluid"
                        />
                        <p class="text-custom">{{ current_user.name }}</p>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="col-lg-8 text-center">
                  <h5 class="text-custom my-4">You will be LIVE in</h5>
                  <div class="countdown">
                    {{ count_number }}
                  </div>
                  <p class="text-white mt-5">
                    {{ visitors.length }} People attending are watching you now
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>

    <transition name="pop" appear>
      <div
        class="modal fade show program-modal"
        v-if="show_modal"
        id="myModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="myModal"
        aria-hidden="true"
        @click="closeModal"
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-body">
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
                @click="closeModal"
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <div class="row" v-if="event">
                <div class="col-lg-12">
                  <div class="c-scroll">
                    <div  v-for="(input,k) in imagesArray" :key="k" class="mb-3">
                    <img
                      :src="input.program_url"
                      class="img-fluid"
                    />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import { getApiManager } from "../../api";
import {
  admin_key,
  apiBaseUrl,
  company_prefix,
  liveBaseUrl,
} from "../../constants/config";
import { mapActions } from "vuex";
import Room from "janus-room";
import { janusUrl, iceServer } from "../../constants/config";
import { Draggable } from "draggable-vue-directive";

import adapter from "webrtc-adapter";
window["adapter"] = adapter;

export default {
  directives: {
    Draggable,
  },
  data() {
    return {
      button_text: "Pass the Mic Override",
      handleId: "handle-id",
      draggableValue: {
        handle: undefined,
      },
      video_source: "",
      room: null,
      plugin: null,
      showbroad: true,
      runningVideo: false,
      runningSourceVideo: false,
      stream: null,
      error: null,
      speakers: [],
      event: null,
      visitors: [],
      current_user: null,
      event_id: null,
      content_type_id: null,
      show_note_modal: false,
      show_count_modal: false,
      show_mini_modal: false,
      count_number: 20,
      connection: null,
      my_info: null,
      current_seq_name: "",
      waiting: true,
      show_modal: false,
      notes: "",
      params: { autoplay: 0 },
      show_css_layout: false,
      alreadyUnmute: false,
      devices: [],
      youtube_url: "",
      OTSession: null,
      subscribers: [],
      videoflag: false,
      apiBaseUrls: "",
    };
  },
  mounted() {
    console.log("&&&&&&&&&&&&&", `${apiBaseUrl}`);
    this.apiBaseUrls = `${apiBaseUrl}`;
    this.draggableValue.handle = this.$refs[this.handleId];
    //current speaker id
    this.event_id = JSON.parse(localStorage.getItem("loginInfo"))
      ? JSON.parse(localStorage.getItem("loginInfo")).event_id
      : 0;
    this.content_type_id = JSON.parse(localStorage.getItem("loginInfo"))
      ? JSON.parse(localStorage.getItem("loginInfo")).id
      : 0;
    getApiManager()
      .post(`${apiBaseUrl}/api/speaker/profile/modifylive`, {
        live: 1,
        content_type_id: this.content_type_id,
      })
      .then((response) => {});
    this.connectSocket();
    this.getDetail();
  },
  methods: {
    live() {
      getApiManager()
        .post(`${apiBaseUrl}/api/vonage/getToken`, {
          decease_name: this.event.decease_name,
        })
        .then((result) => {
          setTimeout(() => {
            this.youtube_url = result.data.channel_id + "&autoplay=1";
            console.log("youtube_url", this.youtube_url);
          }, 25000);
        });
    },
    connectSocket() {
      var ref = this;

      this.connection = new WebSocket(liveBaseUrl);
      this.connection.onmessage = function (event) {
        //this.isLoading = false;
        let data = JSON.parse(event.data);
        console.log(data);
        if (data.type == "change_live") {
          ref.receiveLiveUser(data.order, data.speaker);
        } else if (data.type == "user_info") {
          ref.connection.send(
            JSON.stringify({
              type: "my_info",
              event_id: ref.event_id,
              content_type_id: ref.content_type_id,
            })
          );
        } else if (data.type == "end_live") {
          //route after finishes lives
          localStorage.setItem("Live_id", null);
          localStorage.setItem("deceased_name",'')
          console.log("******************************************");
          let randomnumber = Math.floor(Math.random() * 10000) + 1;
          ref.$router.push("/speaker/parting?x=" + randomnumber);
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        } else if (data.type == "event_distroy") {
          // localStorage.setItem("Live_id", null);
          let randomnumber = Math.floor(Math.random() * 10000) + 1;
          ref.$router.push("/speaker/profile?x=" + randomnumber);
          // window.location.reload();
        }
      };

      this.connection.onclose = (e) => {
        console.log(
          "Socket is closed. Reconnect will be attempted in 1 second."
        );
        setTimeout(() => {
          ref.connectSocket();
        }, 1000);
      };
    },
    showNoteModal() {
      this.show_note_modal = true;
    },
    closeNoteModal() {
      this.show_note_modal = false;
    },
    closeModal() {
      this.show_modal = false;
    },
    enlarge() {
      this.show_modal = true;
    },
    showVideo() {
      this.show_css_layout = false;
    },
    hideVideo() {
      this.show_css_layout = true;
    },
    muteVideo() {
      let target = document.getElementById("remote_video");
      // target.muted = true;
    },
    unMuteVideo() {
      let target = document.getElementById("remote_video");
      // target.muted = false;
    },
    deviceList() {
      var videoDevices = this.room.listVideoDevices();
      var devices = [];
      videoDevices.forEach(function (device) {
        var label = device.label;
        if (!label || label === "") label = device.deviceId;

        if (device.kind === "videoinput") {
          devices.push(device);
        }
      });

      this.devices = devices;
    },
    onLocalJoin() {
      console.log("Receive Local video");
      let target = document.getElementById("remote_video");
      this.runningVideo = true;
      this.room.attachStream(target, 0);
    },
    onRemoteJoin(index, remoteUsername, feedId) {
      console.log("Receive Remote video: " + index + ":" + remoteUsername);
      let target = document.getElementById("remote_video");
      this.runningVideo = true;
      this.room.attachStream(target, index);
      if (!this.alreadyUnmute) {
        this.unMuteVideo();
      }
    },
    onRemoteUnjoin(index) {
      this.runningVideo = false;
    },
    onMessage(data) {
      console.log(data);
    },
    onError(err) {
      console.log(err);
    },
    registerUserName() {
      console.log("User Name is " + this.content_type_id);
      this.room.register({
        username: "speaker_" + this.content_type_id,
        room: parseInt(company_prefix) + this.event_id,
      });
    },
    setStream(stream) {
      if (stream.active) {
        this.stream = stream;
      } else {
        this.stream = null;
      }
    },
    onCleanup() {
      this.stream = null;
      this.status = null;
      this.error = null;
    },
    changeVideoStream(stream) {
      this.stream = stream;
    },
    stopLive() {
      this.runningVideo = false;
      // this.room.unpublishOwnFeed();
    },
    switchCamera(event) {
      var cameraId = event.target.id.substring(
        event.target.id.indexOf("_") + 1
      );
      console.log("You selected: " + cameraId);
      var options = {
        username: "speaker_" + this.content_type_id,
        room: parseInt(company_prefix) + this.event_id,
      };
      // this.room.switchCamera(cameraId, options);
      self.OTpublisher.setVideoSource(cameraId);
      // document.getElementById("selectCamera_" + this.content_type_id).remove();
    },
    startLive() {
      var publishOwnFeed = false;
      var videocodec = "h264";
      var h264_profile = "42e01f";
      console.log("$$$$$$$$$$$$$==========$$$$");
      if (
        this.current_user.content_type_id == this.content_type_id &&
        this.current_user.speaker_type == 1
      ) {
        console.log("$$$$$$$$$$$$$$$$$$$$$$$$");
        publishOwnFeed = true;
      }
      var options = {
        server: janusUrl, // required
        iceservers: iceServer,
        // Event handlers
        onLocalJoin: this.onLocalJoin,
        onRemoteJoin: this.onRemoteJoin,
        onRemoteUnjoin: this.onRemoteUnjoin,
        onMessage: this.onMessage,
        onError: this.onError,
        room: parseInt(company_prefix) + this.event_id,
        // publishOwnFeed: publishOwnFeed,
        videocodec: videocodec,
        h264_profile: h264_profile,
      };
      this.room = new Room(options);
      var ref = this;
      this.room
        .init()
        .then(function () {
          ref.createRoom();
        })
        .catch(function (err) {
          console.log(err);
        });
    },
    createRoom() {
      var ref = this;
      this.room
        .createRoom({
          room: parseInt(company_prefix) + this.event_id,
          publishers: 100,
          admin_key: admin_key,
          videocodec: "h264",
          h264_profile: "42e01f",
        })
        .then(() => {
          setTimeout(function () {
            ref.registerUserName();
          }, 1000);
        })
        .catch((err) => {
          alert(err);
        });
    },
    ...mapActions(["signOut"]),
    logout() {
      if (self.OTSession) {
        self.OTSession.disconnect();
      }
      getApiManager()
        .post(`${apiBaseUrl}/api/speaker/profile/modifylive`, {
          live: 0,
          content_type_id: this.content_type_id,
        })
        .then((response) => {});
      localStorage.setItem("Live_id", null);
      localStorage.setItem("deceased_name",'')
      this.signOut();
      this.$router.push("/login");
      window.location.reload();
    },
    countDown(type = 0) {
      this.show_count_modal = true;
      this.showNoteModal();
      this.count_number = 20;
      this.countDownTimer();
      if (type == 0) {
        if (this.current_user.speaker_type != 0) {
          // this.publishOwnFeed();
        }
      }
    },
    countDownTimer() {
      if (this.count_number > 0) {
        setTimeout(() => {
          this.count_number -= 1;
          this.countDownTimer();
        }, 1000);
      } else {
        this.waiting = true;
        this.show_count_modal = false;
      }
    },
    getDetail() {
      var ref = this;
      getApiManager()
        .post(`${apiBaseUrl}/api/speaker/alllive`)
        .then((response) => {
          let res = response.data;
          if (res.status === true) {
            let detail = res.detail;
            ref.speakers = detail.speakers;
            for (var i = 0; i < ref.speakers.length; i++) {
              ref.speakers[i].profile_url =
                apiBaseUrl + "/" + ref.speakers[i].profile_url;
              if (ref.speakers[i].content_type_id == ref.content_type_id) {
                ref.my_info = ref.speakers[i];
                ref.notes = ref.speakers[i].notes;
              }
            }
            ref.visitors = detail.visitors;
            for (i = 0; i < ref.visitors.length; i++) {
              ref.visitors[i].profile_url =
                apiBaseUrl + "/" + ref.visitors[i].profile_url;
            }

            ref.event = detail.event;
            getApiManager()
          .post(`${apiBaseUrl}/api/admin/event/getuploadImagebyEventId`, {
            event_id:  ref.event.event_id,
          })
          .then((response) => {
            ref.imagesArray=response.data.detail;
              for(var i=0;i<ref.imagesArray.length;i++){
                ref.imagesArray[i].program_url=`${apiBaseUrl}` + "/" + ref.imagesArray[i].program_url
              }
          })
            // ref.event.program_url = apiBaseUrl + "/" + ref.event.program_url;
            ref.setCurrentOrder(ref.event.cur_order);
            ref.setCurrentUser(ref.event.cur_speaker);

            getApiManager()
              .post(`${apiBaseUrl}/api/vonage/getToken`, {
                decease_name: this.event.decease_name,
              })
              .then((result) => {
                setTimeout(() => {
                  ref.startLive();
                  if (ref.current_user.content_type_id != ref.content_type_id) {
                    ref.showCss();
                  } else {
                    ref.Page_Init(
                      ref.current_user.content_type_id,
                      ref.current_user
                    );
                    if (ref.current_user.speaker_type == 1) {
                      ref.countDown(1);
                      ref.showCss();
                    }
                  }
                  this.live();
                }, 2000);
              });
          }
        })
        .catch((error) => {});
    },
    setCurrentUser(speaker) {
      this.event.cur_speaker = speaker;
      console.log(
        "CURRENT_USER_id_________________________________",
        this.event.cur_speaker
      );
      for (var i = 0; i < this.speakers.length; i++) {
        if (this.speakers[i].content_type_id == speaker) {
          this.current_user = this.speakers[i];
        }
      }
      if (this.current_user.speaker_type == 0) {
        this.button_text = "End";
        this.setVideoUrl(this.current_user.video_url);
      } else {
        this.showbroad = true;
        this.runningSourceVideo = false;
        this.video_source = "";
        this.$forceUpdate();
        this.button_text = "Pass the Mic Override";
      }
    },
    setCurrentOrder(order) {
      this.event.cur_order = order;
      for (var i = 0; i < this.speakers.length; i++) {
        if (this.speakers[i].order == order) {
          this.current_seq_name = this.speakers[i].name;
          this.notes = this.speakers[i].notes;
        }
      }
    },
    showCss() {
      this.muteVideo();
      let ref = this;
      ref.alreadyUnmute = true;
      setTimeout(function () {
        ref.showVideo();
        if (ref.current_user.content_type_id != ref.content_type_id) {
          if (ref.runningVideo) {
            ref.unMuteVideo();
          } else {
            ref.alreadyUnmute = false;
          }
        }
      }, 1000 * 11);
    },
    receiveLiveUser(order, speaker) {
      console.log("receive");
      this.setCurrentOrder(order);

      if (speaker == this.content_type_id) {
        console.log("222222222");
        this.waiting = false;
        this.setCurrentUser(speaker);
        this.countDown();
        this.Page_Init(speaker, "");
      } else {
        console.log("3333333333");
        this.videoflag = false;
        for (var i = 0; i < this.speakers.length; i++) {
          if (this.speakers[i].content_type_id == speaker) {
            if (this.speakers[i].speaker_type == 1) {
              getApiManager()
                .post(`${apiBaseUrl}/api/vonage/getToken`, {
                  decease_name: this.event.decease_name,
                })
                .then((result) => {
                  let apikey = result.data.apiKey;
                  let sessionId = result.data.data[0].sessionId;
                  let token = result.data.data[0].token;
                  let broadcastId = result.data.data[0].broadcastId;
                  if (self.OTSession) {
                    self.OTSession.disconnect();
                    console.log(
                      "$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$",
                      self.OTSession
                    );
                  }
                  self.OTSession = self.OT.initSession(apikey, sessionId);
                  var publisherOptions = {
                    insertMode: "before",
                    resolution: "1280x720",
                    width: "100%",
                    height: "100%",
                  };
                  self.OTpublisher = self.OT.initPublisher(
                    "publisher",
                    publisherOptions,
                    handleError
                  );
                  function handleError(error) {
                    if (error) {
                      console.error(error);
                    }
                  }
                });
            }
          }
        }

        this.setCurrentUser(speaker);
        this.stopLive();
      }
      this.showCss();
      if (self.OTpublisher) {
        self.OTpublisher.publishVideo(false);
        // self.OTSession.disconnect();
      }
    },
    setVideoUrl(url) {
      this.runningVideo = false;
      this.showbroad = false;
      this.runningSourceVideo = true;
      if (url.includes("watch") == true) {
        if (
          url.split("=")[1] != null &&
          url.split("=")[1] != undefined &&
          url.split("=")[1] != ""
        ) {
          setTimeout(() => {
            this.video_source =
              "https://www.youtube.com/embed/" +
              url.split("=")[1] +
              "?autoplay=1";
          }, 25000);
        } else {
          setTimeout(() => {
            this.video_source = url;
          }, 25000);
        }
      } else {
        setTimeout(() => {
          this.video_source = url;
        }, 25000);
      }
      this.params = { autoplay: 1 };
      this.$forceUpdate();
      //this.$refs.youtube.src = url;
      // let target = document.getElementById('source_video');
      // target.src = url;
      // target.load();
    },
    changeLiveUser(order, speaker, speakerdata) {
      var ref = this;
      console.log("Change Order" + order, speaker);
      getApiManager()
        .post(`${apiBaseUrl}/api/speaker/event/modify`, {
          cur_order: order,
          cur_speaker: speaker,
        })
        .then((response) => {
          let res = response.data;
          if (res.status === true) {
            ref.connection.send(
              JSON.stringify({
                type: "change_live",
                order: order,
                speaker: speaker,
              })
            );
            ref.setCurrentOrder(order);
            ref.setCurrentUser(speaker);
            console.log("3333333333333333333333333333333333333", speakerdata);
            if (speakerdata.speaker_type == 0) {
              // this.static_video_url = `${apiBaseUrl}/` + speakerdata.video_url;
              this.static_video_url = speakerdata.video_url;

              // setTimeout(() => {
              this.videoflag = true;
              // setTimeout(() => {
              //   var video = document.getElementById("source_video");
              //   console.log("&&&&&&&&&&&&", video);

              //   video.addEventListener(
              //     "canplay",
              //     function () {
              //       console.log("video can play!");
              //     },
              //     false
              //   );
              //   video.oncanplay = function () {
              //     console.log("&&&&&&&&&&&&&&&&&&&&&&&oncanplay");
              //     // video.play();
              //   };
              //   video.onloadstart = function () {
              //     alert("Starting to load video");
              //   };
              //   video.onloadeddata = function () {
              //     alert("Browser has loaded the current frame");
              //   };
              // }, 1000);

              // }, 3000);
            } else {
              this.videoflag = false;
            }

            ref.showCss();
            ref.Page_Init(speaker, speakerdata);
          }
        })
        .catch((error) => {
          // this.$notify({
          //   group: "foo",
          //   type: "warn",
          //   title: "Error",
          //   text: "Server Error",
          //   animation_type: "slide",
          // });
        });
    },
    publishOwnFeed() {
      this.room.publishOwnFeed({
        audioSend: true,
        videoSend: true,
        replaceVideo: true,
        replaceAudio: true,
      });
    },
    endEventLive() {
      var ref = this;

      this.room
        .init()
        .then(function () {
          ref.removeRoom();
        })
        .catch(function (err) {
          console.log(err);
        });
      getApiManager()
        .post(`${apiBaseUrl}/api/speaker/alllive`)
        .then((response) => {
          let res = response.data;
          if (res.status === true) {
            this.speakers = res.detail.speakers;
            for (var i = 0; i < this.speakers.length; i++) {
              getApiManager()
                .post(`${apiBaseUrl}/api/speaker/profile/modifylive`, {
                  live: 0,
                  content_type_id: this.speakers[i].content_type_id,
                })
                .then((response) => {});
            }
          }
        });
      getApiManager()
        .post(`${apiBaseUrl}/api/speaker/event/modify`, { status: 3 })
        .then((response) => {
          let res = response.data;
          if (res.status === true) {
            getApiManager()
              .post(`${apiBaseUrl}/api/vonage/gettoken`, {
                decease_name: this.event.decease_name,
              })
              .then((result) => {
                getApiManager()
                  .post(`${apiBaseUrl}/api/vonage/Stopbroadcast`, {
                    broadcastId: result.data.data[0].broadcastId,
                  })
                  .then((result) => {
                    if (self.OTSession) {
                      self.OTSession.disconnect();
                    }
                    console.log("stop broadcast", result);
                    ref.connection.send(
                      JSON.stringify({
                        type: "end_live",
                      })
                    );
                    localStorage.setItem("Live_id", null);
                    localStorage.setItem("deceased_name",'')
                    let randomnumber = Math.floor(Math.random() * 10000) + 1;
                    this.$router.push("/speaker/profile?x=" + randomnumber);
                  });
              });
          }
        })
        .catch((error) => {
          // this.$notify({
          //   group: "foo",
          //   type: "warn",
          //   title: "Error",
          //   text: "Server Error",
          //   animation_type: "slide",
          // });
        });
    },
    removeRoom() {
      var ref = this;
      this.room
        .removeRoom()
        .then()
        .catch((err) => {
          alert(err);
        });
    },
    endMyLive() {
      this.stopLive();
      console.log("&&&&&", this.speakers);

      for (var i = 0; i < this.speakers.length; i++) {
        if (this.speakers[i].order == this.event.cur_order) {
          console.log("&&&&&&&", this.speakers.length, i);
          if (i == this.speakers.length - 1) {
            this.endEventLive();
          } else {
            this.changeLiveUser(
              this.speakers[i + 1].order,
              this.speakers[i + 1].content_type_id,
              this.speakers[i + 1]
            );
            return;
          }
        }
      }
      // console.log("&&7887877&&&");
      // this.endEventLive();
    },
    ResetCall() {
      getApiManager()
        .post(`${apiBaseUrl}/api/vonage/getToken`, {
          decease_name: this.event.decease_name,
        })
        .then((result) => {
          let sessionId = result.data.data[0].sessionId;
          let apikey = result.data.apiKey;
        });
    },
    passLive() {
      if (this.current_user.speaker_type == 0) {
        console.log("111111111111111");
        for (var i = 0; i < this.speakers.length; i++) {
          if (this.speakers[i].order == this.event.cur_order) {
            if (i == this.speakers.length - 1) {
              this.endEventLive();
            } else {
              this.changeLiveUser(
                this.speakers[i + 1].order,
                this.speakers[i + 1].content_type_id,
                this.speakers[i + 1]
              );
            }
            return;
          }
        }
        this.endEventLive();
      } else {
        console.log("7888888888888888888888888888");
        for (var j = 0; j < this.speakers.length; j++) {
          if (this.speakers[j].order == this.event.cur_order) {
            if (self.OTSession) {
              self.OTSession.disconnect();
              console.log(
                "$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$",
                self.OTSession
              );
            }
            if (j == this.speakers.length - 1) {
              this.endEventLive();
            } else {
              this.changeLiveUser(
                this.speakers[j + 1].order,
                this.speakers[j + 1].content_type_id,
                this.speakers[j + 1]
              );
            }
          }
        }
      }
      // this.changeLiveUser(this.event.cur_order, this.content_type_id);
    },
    Page_Init(speaker, speakerdata) {
      getApiManager()
        .post(`${apiBaseUrl}/api/vonage/getToken`, {
          decease_name: this.event.decease_name,
        })
        .then((result) => {
          let apikey = result.data.apiKey;
          let sessionId = result.data.data[0].sessionId;
          let token = result.data.data[0].token;
          let broadcastId = result.data.data[0].broadcastId;
          if (self.OTSession) {
            self.OTSession.disconnect();
          }

          if (speaker == this.content_type_id) {
            console.log("hereherehererererererrererererrererererer");
            self.OTSession = self.OT.initSession(apikey, sessionId);
            var publisherOptions = {
              insertMode: "before",
              resolution: "1920x1080",
              width: "100%",
              height: "100%",
            };
            self.OTpublisher = self.OT.initPublisher(
              "publisher",
              publisherOptions,
              handleError
            );

            // setTimeout(() => {
            self.OTSession.connect(token, function callback(error) {
              if (error) {
                handleError(error);
              } else {
                // If the connection is successful, publish the publisher to the session
                console.log("new broadcast", broadcastId);

                self.OTSession.publish(self.OTpublisher, handleError);
                getApiManager()
                  .post(`${apiBaseUrl}/api/vonage/broadcast`, {
                    sessionId: sessionId,
                  })
                  .then((result) => {
                    console.log("Brodcast");
                  });

                //   });
              }
            });
            // }, 3000);
            //   }
            // }
          } else {
            if (speakerdata.speaker_type == 0) {
              if (self.OTSession) {
                self.OTSession.disconnect();
              }

              setTimeout(() => {
                // var video = document.getElementById("source_video");
                const video = document.querySelector("video");
                console.log("&&&&&&&&&&&&", video);
                if (video.readyState >= video.HAVE_FUTURE_DATA) {
                  console.log("video can play!");
                  video.play();
                  const stream = video.captureStream();

                  const videoTracks = stream.getVideoTracks();
                  const audioTracks = stream.getAudioTracks();
                  self.OTSession = self.OT.initSession(apikey, sessionId);

                  self.OTpublisher = self.OT.initPublisher(
                    "publisher",
                    {
                      // videoSource: canvas.captureStream(1).getVideoTracks()[0],
                      videoSource: videoTracks[0],
                      audioSource: audioTracks[0],
                      insertMode: "before",
                      resolution: "1280x720",
                      width: "100%",
                      height: "100%",
                    },

                    handleError
                  );
                  self.OTSession.connect(token, function callback(error) {
                    if (error) {
                      handleError(error);
                    } else {
                      // If the connection is successful, publish the publisher to the session
                      console.log("new broadcast", broadcastId);

                      self.OTSession.publish(self.OTpublisher, handleError);
                      getApiManager()
                        .post(`${apiBaseUrl}/api/vonage/broadcast`, {
                          sessionId: sessionId,
                        })
                        .then((result) => {
                          console.log("Brodcast");
                        });

                      //   });
                    }
                  });
                } else {
                  video.addEventListener("loadeddata", (event) => {
                    console.log(
                      "Yay! The readyState just increased to  " +
                        "HAVE_CURRENT_DATA or greater for the first time."
                    );
                    video.play();
                    const stream = video.captureStream();

                    const videoTracks = stream.getVideoTracks();
                    const audioTracks = stream.getAudioTracks();
                    self.OTSession = self.OT.initSession(apikey, sessionId);

                    self.OTpublisher = self.OT.initPublisher(
                      "publisher",
                      {
                        // videoSource: canvas.captureStream(1).getVideoTracks()[0],
                        videoSource: videoTracks[0],
                        audioSource: audioTracks[0],
                        insertMode: "before",
                        resolution: "1280x720",
                        width: "100%",
                        height: "100%",
                      },

                      handleError
                    );
                    self.OTSession.connect(token, function callback(error) {
                      if (error) {
                        handleError(error);
                      } else {
                        // If the connection is successful, publish the publisher to the session
                        console.log("new broadcast", broadcastId);

                        self.OTSession.publish(self.OTpublisher, handleError);
                        getApiManager()
                          .post(`${apiBaseUrl}/api/vonage/broadcast`, {
                            sessionId: sessionId,
                          })
                          .then((result) => {
                            console.log("Brodcast");
                          });

                        //   });
                      }
                    });
                  });
                }
              }, 5000);
            }
          }
          function handleError(error) {
            if (error) {
              console.error(error);
            }
          }
        });
    },
    handlerClose(e, ev, evv) {
      console.log(e);
      console.log(ev);
      console.log(evv);

      this.removefromlive(function () {});
      // e.returnValue = "Are you sure want to leave?";
      console.log("Ddddddddddd");
    },
  },
  created() {
    window.onbeforeunload = this.handlerClose;
  },
  components: {},
  computed: {},
  watch: {},
  beforeDestroy() {},
  destroyed() {
    if (self.OTSession) {
      self.OTSession.disconnect();
    }
  },
};
</script>